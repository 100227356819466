@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Halley";
  src: url("assets/Halley-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Halley", sans-serif;
  background-color: #c3a083;
  background-image: url("./assets/texture.png");
  background-size: cover;
  background-repeat: repeat;
}

.about {
  background-color: #c3a083;
  background-image: url("./assets/texture.png");
  background-repeat: repeat-x;
}

.hero {
  background-image: url("./assets/herobg.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.howto {
  background-image: url("./assets/heropage.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.plank {
  background-image: url("./assets/plankback.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.joinus {
  background-color: rgba(255, 0, 0, 0);
}

/* Media query for mobile view */
@media (max-width: 767px) {
  .hero {
    background-size: 300%;
  }
  .howto {
    background-size: 300%;
  }
  .joinus {
    background-image: url("./assets/joinus.png");
    background-color: black;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.roller {
  animation: move 10s linear infinite;
}

@keyframes move {
  from {
    transform: translateX(-20%);
  }
  to {
    transform: translateX(0%);
  }
}

.roller {
  animation: move 10s linear infinite;
}

.roller-container {
  overflow: hidden; /* Ensure the roller stays within the container */
}
